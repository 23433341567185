<template>
  <v-row class="d-flex justify-start mt-0">
    <v-col cols="8" sm="4" md="3" xl="2">
      <v-card class="pa-4">
        Marca
        <v-autocomplete
          v-model="empresa"
          item-value="id"
          item-text="nombre"
          :items="empresas"
          hide-details
          clearable
          outlined
          dense
        ></v-autocomplete>
      </v-card>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-card>
        <v-card-title class="py-1">
          Acciones
        </v-card-title>
        <v-card-text class="pt-2">
          <BtnConfirmar
            nombre="Actualizar servicios"
            icono="fas fa-sync"
            :texto="`¿Desea actualizar los servicios de <strong>${empresa != null ? empresas.find(e => e.id == empresa).nombre : ''}</strong>?`"
            :disabled="empresa == null"
            @action="actualizar_servicios()"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../components/util/BtnConfirmar'

export default {
  data () {
    return {
      empresa: null
    }
  },
  computed: {
    ...mapState(['empresas'])
  },
  components: {
    BtnConfirmar
  },
  methods: {
    async actualizar_servicios () {
      this.$store.state.loading = true
      await this.$store.dispatch('genericos/actualizar_servicios', this.empresa)
        .then((res) => {
          this.$swal.fire({
            icon: 'success',
            title: res.message
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    }
  }
}
</script>